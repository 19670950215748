<template>
    <div class="notice service detail mw1260">
        <div class="top">
            <div class="t-header">
                <div class="title">
                    아빠미소멘토단의 생생한 소식을 전해드립니다<br>
                    <span>아미단활동 게시판</span>
                </div>
            </div>
        </div>
        <div class="content-wrap">
            <div class="title">
                <!--  관리자가 작성한 제목글이 엄청나게 아주 긴 경우라면 해당부분에 해당 제목이 다 노출되게 계속해서 보여지면 됩니다.  관리자에서 작성한 제목
                  이 2줄이 안 넘어가게 정책서를 조정합시다-->
                {{boardData.wr_subject}}
            </div>
            <div class="info">
                <div class="date">{{returnDateFormat(boardData.wr_datetime)}}</div>
                <div class="content" v-html="decodeHtml(boardData.wr_content)">
                   <!-- 관리자가 작성한 게시글이 여기에 보여지게 되어집니다.
                    관리자에서 작성한대로 해당 글이 보여지게 되어지며, 에디터로 작성하기에 글안에 색상도 그대로 노출하게 되어집니다
                    내용이 길어진다면 해당 부분의 공간도 같이 길어지는 형태로 이루어집니다.
                    감사합니다.-->
                </div>
            </div>
        </div>
        <div class="btn-wrap">
            <button class="full_btn list_btn" @click="$router.back()">목록</button>
        </div>
    </div>
</template>

<script>
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex"

    export default {
        name: "BoardDetailAmidanLayout",
        mixins: [],
        components: {},
        props: {
            boardData: {
                type: Object,
                default: () => {
                    return {}
                },
            },
            categorys: {
                type: Array,
                default: () => {
                    return []
                },
            },
        },
        data() {
            return {}
        },
        beforeRouterEnter() {
        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            decodeHtml(html) {
                return util.htmlDecode(html);
            },
            returnDateFormat(date) {
                if (!date) {
                    return '';
                }
                return this.$moment(date).format('YYYY-MM-DD HH:mm')
            },
            backEvent() {
                this.$modal.hide('amidan-detail-modal')
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>
